<template>
    <div class="row">
        <div class="col col-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="fw-bold mb-3">
                        <i class="fad fa-list-ul me-2"></i> Rubriques
                    </h5>
                    <div class="d-grid">
                        <router-link :to="{name: 'settings_categories'}" class="btn btn-primary">
                            <i class="fad fa-pen-alt me-2"></i> Modifier les rubriques
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>