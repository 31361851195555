import UploadManager from '@/services/api/manager/upload.manager'
import {TokenService} from '@/services/storage/storage.service'
import {generateKey} from '@/helpers/key-gen.helper'
import {awsResponseParser} from '@/helpers/xmltojson.helper'

import axios from 'axios'

const state = {}

const actions = {

    async fetchUploadSignature({dispatch}, {fileRef}) {

        try {

            const response = await UploadManager.fetchSignature(fileRef);
            return response.data

        } catch (error) {

            dispatch('showErrorToast', {
                key: "upload_signature_error"
            }, {root: true})

            return false
        }
    },

    async saveFile ({dispatch}, {response, filename, fileRef}) {

        let file_infos = awsResponseParser(response)

        file_infos.file_name = filename
        file_infos.file_ref = fileRef

        UploadManager.savePicture(file_infos).then(() => {

            dispatch('showSuccessToast', {
                key: "upload_picture_saved_success"
            }, {root: true})
        });

    },


    async startUpload ({dispatch}, {fileRef, files}) {

        files.forEach((file) => {

            dispatch('fetchUploadSignature', {fileRef}).then((data) => {

                dispatch('upload', {file, params: data.signature, fileRef})

            })
        });
    },


    async upload({dispatch, commit}, {file, params, fileRef})
    {

        // create a new FormData
        let data = new FormData();

        data.append('acl', params.acl)
        data.append('success_action_status', params.successActionStatus)
        data.append('policy', params.policy);
        data.append('X-amz-credential', params.credentials);
        data.append('X-amz-algorithm', params.algorithm);
        data.append('X-amz-date', params.date);
        data.append('X-amz-expires', params.expires);
        data.append('X-amz-signature', params.signature);
        data.append('key', params.key);
        data.append('Content-Type', file.type);
        data.append('file', file);

        file.id = generateKey(15)
        file.progress = 0

        axios({
            method: 'post',
            url: params.action,
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': ''
            },
            onUploadProgress (progressEvent) {
                /*commit(types.UPDATE_UPLOAD_PROGRESS,
                 {
                 file_id: file.id,
                 progress: parseInt(Math.round(( progressEvent.loaded * 100 ) / progressEvent.total))
                 }
                 )*/
            }
        }).then((response) => {

            setTimeout(() => {
                /*commit(
                 types.REMOVE_UPLOADING_FILE,
                 file.id
                 );*/
            }, 5000)

            dispatch(
                'saveFile',
                {
                    response,
                    filename: file.name,
                    fileRef
                }
            )

        }).catch((e) => {

            console.error(e)
            console.error('@debug: File couldn\'t been uploaded')
        });

    },
}

const getters = {}

const mutations = {}

export const upload = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}