import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'

const SettingsManager = {
    fetchCategories() {
        return ApiService.query('settings/fetch-categories')
    },
    fetchCategory(id) {
        return ApiService.query(`settings/fetch-category/${id}`)
    },
    createCategory(data) {
        return ApiService.post(`settings/create-category`, data)
    },
}
export default SettingsManager
