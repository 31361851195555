<template>
    <div class="form-group my-3">
        <h6 class="fw-bold m-0">Date(s) de publication</h6>
        <small class="d-block">Choisissez les dates de publications à facturer lors de la prochaine facture</small>
        <div v-if="highlightedDates">
            <p class="text-secondary m-1">Ajouter une date de publication :</p>
            <DatePicker :attributes="attributes" @dayclick="onDayClick" v-model="model"/>
        </div>
        <p class="mt-2 mb-1 text-secondary">Dates de publication associées :</p>
        <div class="mt-1">
            <div class="p-2 border rounded me-1 mb-1 d-inline-block"
                 v-for="(advertDate, index) in advert.advertDates" :key="index">
                <span v-if="advertDate.invoiced" class="badge bg-success">Facturée</span>
                <div v-if="!advertDate.invoiced" class="custom-control custom-checkbox">
                    <input type="checkbox" @click="toggleInvoicable(advertDate.id)"
                           class="custom-control-input me-2"
                           :id="advertDate.date"
                           :checked="advertDate.invoicable"
                    >
                    <label class="custom-control-label" :for="advertDate.date">Facturer cette date</label>
                </div>
                <p class="m-0">
                    <b>{{ advertDate.date }}</b>
                </p>
                <a v-if="!advertDate.invoiced"
                   @click.prevent="removeDate(advertDate.date)" href="#"
                   class="btn btn-sm bg-danger text-white">
                    <i class="fad fa-trash"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {Calendar, DatePicker} from 'v-calendar';
import {ref} from 'vue';
import {mapGetters} from "vuex";

export default {
    components: {Calendar, DatePicker},
    props: ['advert'],
    data() {
        return {
            model: [],
            selectedDates: [],
        }
    },
    computed: {
        ...mapGetters('publication', [
            'getPublicationDates'
        ]),
        highlightedDates() {
            return this.getPublicationDates.map(d => new Date(d.start));
        },
        attributes() {
            return this.highlightedDates.map(date => ({
                highlight: {
                    color: 'blue',
                    fillMode: 'light',
                },
                dates: date,
            }));
        },
    },
    methods: {
        onDayClick(date) {
            console.log(date)
            const idx = this.selectedDates.findIndex(d => d.id === date.id);
            if (idx >= 0) {
                this.selectedDates.splice(idx, 1);
            } else {
                this.selectedDates.push({
                    id: date.id,
                    date: date.date,
                });
            }
            this.$store.dispatch('publication/addPublicationDate', {classified: this.advert, date: date.id})
            //this.$emit('update-dates', this.selectedDates)
        },
        dateSelected(date) {
            date = format(date)
            console.log(date)
            //@Deprecated
            if (undefined === this.advert.publicationDates.find(el => el === date)) {
                this.advert.publicationDates.push(date)
            }
            if (undefined === this.advert.advertDates.find(el => el === date)) {
                this.$store.dispatch('publication/addPublicationDate', {classified: this.advert, date})
            }
        },
        removeDate(date) {
            let index = this.advert.advertDates.findIndex(el => el.date === date)
            //@Deprecated
            //this.advert.publicationDates.splice(index, 1)
            this.advert.advertDates.splice(index, 1)
        },
    }
}
</script>