import {mapGetters, mapActions} from "vuex";

export default {
    data() {
        return {
            client() {
                return {}
            },
            form: {
                firstName: true,
                lastName: true,
                email: true,
                type: 1,
                phone: true
            }
        }
    },

    computed: {
        ...mapGetters('client', [
            'fetching',
            'loadedClient',
            'newClient'
        ]),
        id() {
            return this.$route.params.id
        }
    },

    created() {

        if (!this.action) {
            throw new Error('You have to define action type to your component in order to work with the client form mixin')
        }

        if (this.id) {
            this.fetchOne({
                id: this.id
            })
        }

        // --- editing mode
        if (this.action === 2) {

            this.client = this.loadedClient
        } else {
            // --- Adding mode
            this.client = this.newClient
        }

        this.client = this.newClient

    },

    watch: {
        loadedClient(client) {
            this.client = client
        }
    },

    methods: {

        ...mapActions('client', [
            'fetchOne',
        ]),

        submit() {

            let error = 0;

            if (this.client.email.length) {
                // Email should be valid
                if (!this.validateEmail(this.client.email)) {
                    alert("L'email n'est pas valide")
                    this.form.email = false
                    error = 1
                } else this.form.email = true
            }

            // First name should be less than 100 characters
            if (this.client.firstName.length > 100) {
                alert('Le prénom doit être inférieur à 100 caractères')
                this.form.firstName = false
                error = 1
            } else this.form.firstName = true

            // Last name should be less than 100 characters
            if (this.client.lastName.length > 100) {
                alert('Le nom doit être inférieur à 100 caractères')
                this.form.lastName = false
                error = 1
            } else this.form.lastName = true

            // The last name should be defined
            if (!this.client.lastName.length) {
                alert('Le nom du client doit être défini')
                this.form.lastName = false
                error = 1
            } else this.form.lastName = true


            if (!error) {

                let action = 'client/add';

                if (this.action === 1) {
                    action = 'client/add'
                }
                if (this.action === 2) {
                    action = 'client/edit'
                }

                this.$store.dispatch(
                    action,
                    {
                        client: this.client
                    }
                )
            }
        },

        validateEmail(email) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    },
}