import EditingManager from '@/services/api/manager/editing.manager'

import {TokenService} from '@/services/storage/storage.service'
import {generateReference} from '@/helpers/key-gen.helper'

let types = {
    SET_EDIT: 'setEdits'
}


const state = {
    edits: []
}

const actions = {

    fetchEdits({commit}) {

        EditingManager.fetch().then(({data}) => {

            commit(types.SET_EDIT, data.edits)

        })
    },

    generateEdit({commit, dispatch}, edition) {

        EditingManager.generate(edition).then(({data}) => {
            if (data.status === 'success')
                dispatch('showSuccessToast', {
                    key: "edit_generate_success"
                }, {root: true})
        })
    },

}

const getters = {
    getEdits(state) {
        return state.edits
    }
}

const mutations = {
    [types.SET_EDIT] (state, edits) {
        state.edits = edits
    }
}

export const editing = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}