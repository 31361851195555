import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'


const PublicationDateManager = {

    select: async function (date) {

        return await ApiService.post('publications-dates/add', {date})
    },

    fetch: async function () {

        return await ApiService.get('publications-dates/fetch')
    },

    remove: async function (id) {

        return await ApiService.delete(`publications-dates/remove/${id}`)
    },

}

export default PublicationDateManager
