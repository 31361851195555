<template>
    <div class="form-group my-4">
        <label>
            <h6 class="fw-bold">Description</h6>
            <h6 v-if="title.length">
                <span class="badge bg-light text-black-50">Titre : {{ title.length }} caractères</span>
            </h6>
            <h6>
                <span class="badge text-black-50"
                      :class="{'bg-light': !additional,'bg-warning': additional}">Forfait</span>
                <span class="badge text-black-50 badge-pill "
                      :class="{'badge-light': (descriptionLength > descriptionMax)}"
                >
                                {{ descriptionLength }} / {{ descriptionMax }}
                            </span>
            </h6>
            <h6 v-if="(descriptionLength > descriptionMax)" class="mt-2">
                <span class="badge text-black-50 bg-warning">Lignes supplémentaires : x{{ suppRows }}</span>
            </h6>
        </label>
        <textarea
            ref="descriptionTextarea"
            @keyup="onDescriptionChange()"
            v-model="desc"
            class="form-control"
            rows="6">
        </textarea>
    </div>
</template>

<script>
export default {
    name: "classified-description",
    props: ['description', 'descriptionSupp', 'title'],
    data() {
        return {
            desc: '',
            descSupp: '',
            descriptionMax: 90,
            additional: false,
            rowLength: 30,
            suppRows: 0,
            supp: '',
        }
    },
    computed: {
        descriptionLength() {
            return this.desc.length + this.title.length
        }
    },
    created() {
        this.desc = this.description
    },
    methods: {
        onDescriptionChange() {
            this.$emit('description-change', this.desc)
            if (this.descriptionLength > this.descriptionMax) {
                this.additional = true
                // --- Additional lines of description
                this.suppRows = Math.ceil(
                    (this.descriptionLength - this.descriptionMax) / this.rowLength
                )
                this.$emit('description-supp-change', this.descSupp)
            }
        },
    },
    watch: {
        description() {
            this.desc = this.description
            this.onDescriptionChange()
        },
        title() {
            this.onDescriptionChange()
        },
    }
}
</script>
