<template>
    <Form :classified="newClassified" :action="'add'" v-on:submit="submit"></Form>
</template>

<script>
    import Form from '@/components/Classified/Form.vue'
    import {generateReference} from '@/helpers/key-gen.helper'

    export default {
        name: "create-classified",

        computed: {
            newClassified() {
                return this.$store.getters['classified/getNewClassified']
            }
        },

        methods: {
            submit(classified)
            {
                this.$store.dispatch(
                    'classified/create',
                    {
                        classified
                    }
                )
            }
        },

        components: {
            Form
        }
    }
</script>

<style lang="scss">
    .feather.feather--save {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .form-control[readonly] {
        background-color: #fff !important;
    }

    .vdp-datepicker__calendar .cell.highlighted {
        background: lighten(#2196F3, 10%);
        color: #fff;
    }

    .vdp-datepicker__calendar .cell.selected.highlighted {
        background: #2196F3;
    }

    .vdp-datepicker__calendar .cell.selected {
        background: #f1f1f1;
    }
</style>