<template>
    <header class="py-3 sticky-top bg-white border-bottom">
        <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 1fr 2fr;">
            <div class="dropdown">

                <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownNavLink" style="">
                    <li><a class="dropdown-item active" href="#" aria-current="page">Overview</a></li>
                    <li><a class="dropdown-item" href="#">Inventory</a></li>
                    <li><a class="dropdown-item" href="#">Customers</a></li>
                    <li><a class="dropdown-item" href="#">Products</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Reports</a></li>
                    <li><a class="dropdown-item" href="#">Analytics</a></li>
                </ul>
            </div>

            <div class="d-flex align-items-center">
                <SearchBar></SearchBar>
                <div v-if="appUser" class="flex-shrink-0 dropdown">
                    <a @click="openDropDown" href="#" class="d-block link-dark text-decoration-none dropdown-toggle"
                       id="dropdownUser2"
                    >
                        <img :src="appUser.avatar_url" :alt="appUser.username"
                             class="rounded me-2"
                             width="35"
                             height="35">
                    </a>
                    <ul class="dropdown-menu my-3 text-small" aria-labelledby="dropdownUser2" :class="{'open': openState}">
                        <li class="px-3 py-2">
                            <i class="fad fa-user me-2"></i> <b>{{ appUser.username }}</b>
                        </li>
                        <li>
                            <a @click.prevent="logout" href="#" class="dropdown-item">
                                <i class="fad fa-sign-out-alt me-2"></i> Se déconnecter
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="mx-3" v-else>
                    <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import SearchBar from '@/components/SearchBar'
import {mapState} from 'vuex'

export default {
    data() {
        return {
            version: '1.1',
            openState: false
        }
    },
    computed: {
        ...mapState({
            appUser: state => state.auth.user,
        }),
    },
    created() {
        console.log(this.version)
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
        },
        openDropDown() {
            this.openState = !this.openState
        }
    },
    components: {
        SearchBar
    }
}
</script>

<style lang="scss">

header {
    z-index: 1 !important;
}

#main-logo__container {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

#main-logo {
    height: 40px;
}

.open {
    display: block !important;
    right: 0;
}
</style>