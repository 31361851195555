<template>
    <form>
        <div class="row">
            <div class="col-6">
                <div class="form-group my-3">
                    <h6 class="fw-bold">Titre de la rubrique</h6>
                    <input v-model="data.title" class="form-control" type="text" placeholder="Titre de la rubrique">
                </div>
                <div class="form-group my-3">
                    <h6 class="fw-bold">Rubrique parente</h6>
                    <div class="form-check form-switch">
                        <input v-model="data.isParent" class="form-check-input" type="checkbox" id="categoryIsParent">
                        <label class="form-check-label" for="categoryIsParent">
                            Rubrique parente
                        </label>
                    </div>
                    <div v-if="!data.isParent">
                        <select v-if="categories.length" v-model="selectedParentCategory" class="form-select"
                                aria-label="Default select example">
                            <option value="0">Sélectionner une rubrique parente</option>
                            <option v-for="category in parentCategories">{{ category.name }}</option>
                        </select>
                        <div v-else class="spinner-grow spinner-grow-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <small class="text-muted">
                            Cette rubrique est actuellement parente, vous pouvez lui associer
                            d'autres rubriques.
                        </small>
                    </div>
                </div>
                <a @click.prevent="save" class="btn btn-primary"><i class="far fa-plus-circle me-1"></i> Enregistrer</a>
            </div>
        </div>
    </form>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data() {
        return {
            data: {
                title: '',
                isParent: 0
            },
            selectedParentCategory: 0
        }
    },
    computed: {
        ...mapState('settings', ['categories']),
        parentCategories() {
            return this.categories.filter(c => c.is_parent)
        }
    },
    created() {
        this.$store.dispatch('settings/getCategories')
    },
    methods: {
        save() {
            this.$emit('save', this.data)
        }
    }
}
</script>