<template>
    <section>
        <div class="card text-center">
            <div class="card-header">
                Toutes les facture

            </div>
            <div class="card-body">

                <div v-if="!getInvoices.length" class="d-flex justify-content-center">
                    <div class="spinner-border spinner-sm text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Number</th>
                        <th scope="col">Client</th>
                        <th scope="col">Price</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="getInvoices.length" v-for="invoice in getInvoices" :key="invoice.number">
                        <td><b>{{ invoice.number }}</b></td>
                        <td>
                            <feather type="user"></feather>
                            {{ invoice.client.name }}
                        </td>
                        <td>
                            <h6>
                                <feather type="user"></feather>
                                <span class="badge badge-warning"><b>{{ invoice.price }} XPF</b></span>
                            </h6>
                        </td>
                        <td>
                            <a target="_blank" :href="invoice.url" class="btn btn-sm btn-success mr-1">
                                <feather type="eye"></feather>
                                Ouvrir la facture
                            </a>
                            <router-link class="btn btn-sm btn-primary" :to="{name: 'invoicing_edit_payment', params: {id: invoice.id}}">
                                <feather type="credit-card"></feather>
                                informations de paiement
                            </router-link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "invoicing-list",
        data() {
            return {
                searchClient: '',
                clientPicked: false
            }
        },
        computed: {
            getInvoices() {
                return this.$store.getters['invoicing/getInvoices']
            }
        },
        created() {
            this.$store.dispatch('invoicing/fetch')
        }
    }
</script>