import App from './App.vue'
import store from './store'
import {createApp} from "vue";
import router from "./router";
import './registerServiceWorker'
import {emitter} from "./bus";

//--- Toasts
import Toaster from "@meforma/vue-toaster";

//--- Bootstrap & custom styles
import '@/style/main.scss'

//--- Api & Token services
import {TokenService} from '@/services/storage/storage.service'
import ApiService from '@/services/api/api.service'

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API)

// If token exists set header
if (TokenService.getToken()) {
    ApiService.setHeader()
    ApiService.mountInterceptors()
}

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(router);
app.use(store);
app.use(Toaster);
app.mount("#app");