<template>
    <div>
        <table class="table table-bordered">
            <thead>
            <tr>
                <th scope="col">Rubrique</th>
                <th scope="col">Rubrique parente</th>
                <th scope="col">Date de création</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="categorie in categories">
                <td>{{ categorie.name }}</td>
                <td>{{ categorie.parent_name }}</td>
                <td>{{ categorie.created_at }}</td>
                <td>
                    <router-link :to="{name: 'settings_category_edit', params: {id: categorie.id}}"
                                 title="Modifier" class="btn btn-sm btn-primary">
                        <i class="fad fa-pencil-alt"></i>
                    </router-link>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <teleport to="#view-header__buttons" v-if="isMounted">
        <router-link :to="{name: 'settings_category_create'}" href="" class="btn btn-primary">
            <i class="far fa-plus-circle me-1"></i> Créer une nouvelle rubrique
        </router-link>
    </teleport>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data: function(){
        return {
            isMounted: false
        }
    },
    computed: {
        ...mapState('settings', ['categories'])
    },
    created() {
        this.$store.dispatch('settings/getCategories')
    },
    mounted(){
        this.isMounted = true
    }
}
</script>