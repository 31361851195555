import ClassifiedManager from '@/services/api/manager/classified.manager'
import {TokenService} from '@/services/storage/storage.service'
import router from '@/router'
import {generateReference} from '@/helpers/key-gen.helper'

let types = {
    CATEGORIES_START_FETCHING: 'categoriesStartFetching',
    CATEGORIES_STOP_FETCHING: 'categoriesStopFetching',
    SET_CATEGORIES: 'setCategories',
    SET_CLASSIFIEDS: 'setClassifieds',
    SET_CLASSIFIED: 'setClassified',
    REMOVE_CLASSIFIED: 'removeClassified',
    ADD_CLASSIFIED: 'addClassified',
    CLEAR_CLASSIFIEDS: 'clearClassifieds',
    SET_CLASSIFIED_REF_ID: 'setClassifiedRefId',
    REMOVE_CLASSIFIED_PICTURE: 'removeClassifiedPicture',
    RESET_CLASSIFIED: 'resetClassified',
    TOGGLE_DATE_INVOICABILITY: 'toogleDateInvoicability'
}

function NewClassifiedState() {
    return {
        clientPickerMethod: 0,
        clientId: false,
        clientName: '',
        title: '',
        advertDates: [],
        publicationDates: [],
        category: 0,
        description: '',
        descriptionSupp: '',
        options: [],
        pictures: [],
        reference: '',
        fileRef: ''
    }
}

const state = {
    categoriesFetching: false,
    categories: [],
    classifieds: [],
    new_classified: NewClassifiedState(),
    loaded_classified: {},
    nextRefId: null
}

const actions = {

    fetch({commit}, params) {

        ClassifiedManager.fetch(params).then(classifieds => {

            commit(types.SET_CLASSIFIEDS, classifieds)
        });
    },

    fetchOne({commit}, classified_id) {

        ClassifiedManager.fetchOne(classified_id).then(classified => {

            commit(types.SET_CLASSIFIED, classified)
        });
    },

    fetchReference({commit}, {paper_id}) {

        ClassifiedManager.fetchReference(paper_id).then(refId => {
            commit(types.SET_CLASSIFIED_REF_ID, refId)
        });
    },

    fetchCategories({commit}) {

        commit(types.CATEGORIES_START_FETCHING)

        ClassifiedManager.fetchCategories().then((categories) => {

            commit(types.CATEGORIES_STOP_FETCHING)
            commit(types.SET_CATEGORIES, categories)
        })
    },

    remove({commit}, classified) {

        commit(types.REMOVE_CLASSIFIED, classified.reference)

        ClassifiedManager.remove(classified.id)
    },

    save({commit, dispatch}, classified) {

        console.log(classified)

        ClassifiedManager.save(classified).then(response => {

            commit(types.ADD_CLASSIFIED, response.data.classified)
            router.push({name: 'classified_list'})

            if (response.data.status === 'success')
                dispatch('showSuccessToast', {
                    key: "classified_save_success"
                }, {root: true})

        })
    },

    duplicate({commit}, id) {

        let reference = generateReference()

        ClassifiedManager.duplicate(id, reference).then(classified => {

            commit(types.ADD_CLASSIFIED, classified)

        })

    },

    clear({commit}) {
        commit(types.CLEAR_CLASSIFIEDS)
    },

    create({commit, dispatch}, {classified}) {

        ClassifiedManager.create(classified).then(response => {

            if (!response.data) return
            if (response.data.status === 'success')
                dispatch('showSuccessToast', {
                    key: "classified_add_success"
                }, {root: true})

            commit(types.RESET_CLASSIFIED)
            router.push({name: 'classified_list'})
        })

    },

    removePicture({commit, dispatch}, {pictureId, classified}) {

        ClassifiedManager.deletePicture(pictureId).then(response => {

            commit(types.REMOVE_CLASSIFIED_PICTURE, {pictureId, classified})

            dispatch('showSuccessToast', {
                key: "classified_remove_picture_success"
            }, {root: true})
        })
    },
}

const getters = {

    getCategories(state) {
        return state.categories
    },

    getClassifieds(state) {
        return state.classifieds
    },

    getNewClassified(state) {
        return state.new_classified
    },

    getClassified(state) {
        return state.loaded_classified
    },

    isGategoriesFetching(state) {
        return state.categoriesFetching
    },

    getNextRefId(state) {
        return state.nextRefId
    }
}

const mutations = {

    [types.CATEGORIES_START_FETCHING](state) {
        state.categoriesFetching = true
    },

    [types.CATEGORIES_STOP_FETCHING](state) {
        state.categoriesFetching = false
    },

    [types.SET_CATEGORIES](state, categories) {
        state.categories = categories
    },

    [types.SET_CLASSIFIEDS](state, classifieds) {
        state.classifieds = classifieds
    },

    [types.SET_CLASSIFIED](state, classified) {
        state.loaded_classified = classified
    },

    [types.REMOVE_CLASSIFIED](state, reference) {

        let index = state.classifieds.findIndex(c => c.reference === reference)
        state.classifieds.splice(index, 1)
    },

    [types.ADD_CLASSIFIED](state, classified) {

        state.classifieds.push(classified)
    },

    [types.CLEAR_CLASSIFIEDS](state) {

        state.classifieds = []
    },

    [types.RESET_CLASSIFIED](state) {

        state.new_classified = NewClassifiedState()
    },

    [types.SET_CLASSIFIED_REF_ID](state, refId) {

        state.loaded_classified.refId = refId
        state.new_classified.refId = refId
    },

    [types.REMOVE_CLASSIFIED_PICTURE](state, {pictureId, classified}) {
        let index = classified.pictures.findIndex(p => p.id === pictureId)
        classified.pictures.splice(index, 1)
    },

    [types.TOGGLE_DATE_INVOICABILITY](state, advertDateId) {
        let array = state.loaded_classified.advertDates
        let index = array.findIndex(ad => ad.id === advertDateId)
        let advertDate = array.find((e, i) => i == index);
        advertDate.invoicable = !advertDate.invoicable
        array.splice(index, 1, advertDate)
    },
}

export const classified = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}