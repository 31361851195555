<template>
    <Form v-if="classified" :classified="classified" :action="'edit'" v-on:submit="save"></Form>
</template>

<script>
    import Form from '@/components/Classified/Form.vue'

    const FETCH_ONE = 'classified/fetchOne'
    const GET_CLASSIFIED = 'classified/getClassified'

    const SAVE_CLASSIFIED = 'classified/save'

    export default {

        name: "edit-client",

        created() {
            this.$store.dispatch(FETCH_ONE, this.classifiedId)
        },

        computed: {
            classifiedId() {
                return this.$route.params.id
            },
            classified() {
                return this.$store.getters[GET_CLASSIFIED]
            }
        },

        methods: {
            save(classified) {
                this.$store.dispatch(SAVE_CLASSIFIED, classified)
            }
        },

        components: {
            Form
        }
    }

</script>

<style scoped="true">
    .feather.feather--user-plus {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>