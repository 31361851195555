<template>
    <div class="card">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">#Reference</th>
                <th scope="col">Date de création</th>
                <th scope="col">Client</th>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th colspan="3" scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <InvoicingClassified v-for="classified in classifieds"
                                 :key="classified.reference"
                                 :classified="classified">
            </InvoicingClassified>
            </tbody>
        </table>
    </div>
</template>

<script>
    import InvoicingClassified from '@/components/Invoicing/InvoicingClassified'
    export default {
        props: ["classifieds"],
        components: {
            InvoicingClassified
        }
    }
</script>