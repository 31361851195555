<template>
    <form>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <h6 class="fw-bold">Titre de la rubrique</h6>
                    <input v-model="currentCategory.name" class="form-control" type="text" placeholder="Titre de la rubrique">
                </div>
                <div class="form-group my-3">
                    <h6 class="fw-bold">Rubrique parente</h6>
                    <p v-if="currentCategory.is_parent">
                        <span class="badge bg-info">Cette rubrique est parente</span>
                    </p>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import {mapState} from 'vuex'
export default {
    computed: {
        ...mapState('settings', ['currentCategory'])
    },
    created () {
        this.$store.dispatch('settings/getCategory', this.$route.params.id)
    }
}
</script>