<template>
    <form>
        <div class="row">
            <div class="col-6">
                <h6 class="fw-bold">Nom & Prénom du client :</h6>
                <div class="form-group my-3">
                    <input v-model="client.lastName" class="form-control mb-1" type="text"
                           :class="{'border border-danger': !form.lastName}"
                           placeholder="Nom du client">
                    <input v-model="client.firstName" class="form-control" type="text"
                           :class="{'border border-danger': !form.firstName}"
                           placeholder="Prénom du client">
                </div>
                <div class="form-group my-3">
                    <h6 class="fw-bold">Email du client :</h6>
                    <input v-model="client.email" class="form-control" :class="{'border border-danger': !form.email}"
                           type="text"
                           placeholder="Email du client">
                </div>
                <div class="form-group my-3">
                    <h6 class="fw-bold">Numéro de téléphone du client :</h6>
                    <input v-model="client.phone" class="form-control" type="text"
                           :class="{'border border-danger': !form.phone}"
                           placeholder="Numéro de téléphone du client">
                </div>
                <div class="form-group my-4">
                    <h6 class="fw-bold">Type de client :</h6>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input me-2"
                               value="1"
                               v-model="client.type">
                        <label class="custom-control-label" for="customRadio1">
                            <h6><span class="badge bg-light text-black-50">Particulier</span></h6>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input me-2"
                               value="2"
                               v-model="client.type">
                        <label class="custom-control-label" for="customRadio2">
                            <h6><span class="badge bg-dark">Professionnel</span></h6>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input me-2"
                               value="3"
                               v-model="client.type">
                        <label class="custom-control-label" for="customRadio3">
                            <h6><span class="badge bg-warning text-black-50">Professionnel crédit</span></h6>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col">
                <button @click="submit" type="button" class="btn btn-primary">
                    <i class="fad fa-user-plus"></i>
                    {{ submitTitle }}
                </button>
            </div>
        </div>
    </form>
</template>


<script>
import clientFormMixin from '@/mixins/client-form.mixin'

export default {
    props: {
        action: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            submitTitle: 'Ajouter'
        }
    },
    created() {
        if (this.action === 2) {
            this.submitTitle = 'Modifier'
        }
    },
    mixins: [clientFormMixin]
}
</script>