import PublicationDateManager from '@/services/api/manager/publication-date.manager'
import {TokenService} from '@/services/storage/storage.service'

const SET_PUBLICATION_DATES = 'setPublicationDates'
const ADD_PUBLICATION_DATE = 'addPublicationDate'
const REMOVE_PUBLICATION_DATE = 'removePublicationDate'

const state = {
    publicationDates: [],
}

const actions = {

    async fetch({commit}) {

        let response = await PublicationDateManager.fetch();
        commit(
            SET_PUBLICATION_DATES,
            response.data.dates
        )
        return response.data.dates
    },

    async select({state, commit, dispatch}, date) {
        if (state.publicationDates.filter(d => d.start === date).length) return
        try {
            let response = await PublicationDateManager.select(date);
            if (response.data.date) {
                commit(ADD_PUBLICATION_DATE, response.data.date)
                dispatch('showSuccessToast', {
                    key: "publication_date_selected_success"
                }, {root: true})
            }
        } catch (e) {
            return false
        }
    },

    async remove({commit}, id) {
        try {
            let response = await PublicationDateManager.remove(id);
            if (response.status == 200) {
                commit(REMOVE_PUBLICATION_DATE, id)
                return true
            }
        } catch (e) {
            return false
        }
    },

    addPublicationDate({commit}, {classified, date}) {
        classified.advertDates.push({
            date,
            id: null,
            invoicable: 0,
            invoiced: false,
            invoiced_at: null
        })
    }

}

const getters = {
    isFetching: (state) => {
        return state.fetching
    },
    getPublicationDates: (state) => {
        return state.publicationDates
    },
}

const mutations = {
    [SET_PUBLICATION_DATES](state, publicationDates) {
        state.publicationDates = publicationDates;
    },
    [ADD_PUBLICATION_DATE](state, publicationDate) {
        state.publicationDates.push(publicationDate)
    },
    [REMOVE_PUBLICATION_DATE](state, publicationDateId) {
        let index = state.publicationDates.findIndex(pubDate => pubDate.id === publicationDateId)
        state.publicationDates.splice(index, 1)
    },
}

export const publication = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}