import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    login: async function (username, password) {
        const requestData = {
            method: 'post',
            url: "/generate/token/",
            data: {
                grant_type: 'password',
                username: username,
                password: password
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            if(response.data.token !== undefined) {

                TokenService.saveToken(response.data.token)
                TokenService.saveRefreshToken(response.data.refresh_token)
                ApiService.setHeader()

                return response.data
            }

            return false

        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },

    /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
     **/
    logout() {
        // Remove the token and remove Authorization header from Api Service as well
        TokenService.removeToken()
        TokenService.removeRefreshToken()
        ApiService.removeHeader()
    },

    identify() {
        return ApiService.get('user/identify')
    }
}

export default UserService

export {UserService, AuthenticationError}