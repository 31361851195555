<template>
  <div class="home">
    <Header></Header>
    <div class="container-fluid">
      <div class="row">
        <Sidebar></Sidebar>
        <main role="main" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <ViewHeader></ViewHeader>
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/views/Header.vue'
import Sidebar from '@/views/Sidebar.vue'
import ViewHeader from '@/views/ViewHeader.vue'

export default {
  name: 'home',
  components: {
    Header,
    Sidebar,
    ViewHeader
  }
}
</script>
