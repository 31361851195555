import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'


const ClassifiedManager = {

    create (classified) {
        return ApiService.post('classified/create', classified)
    },

    fetch(params) {

        return ApiService.query('classified/fetch', params).then(response => {
            return response.data.classifieds
        })

    },

    fetchOne(id) {

        return ApiService.get(`classified/fetch/${id}`).then(response => {
            return response.data.classified
        })

    },

    fetchReference(n_ti) {

        return ApiService.get(`classified/fetch/reference/${n_ti}`).then(response => {
            return response.data.reference_id
        })

    },

    save(classified) {

        return ApiService.put(`classified/save/${classified.id}`, classified)

    },

    duplicate(id, reference) {

        return ApiService.post(`classified/duplicate/${id}`, {reference}).then(response => {
            return response.data.classified
        })

    },

    remove(id) {

        return ApiService.delete(`classified/remove/${id}`).then(() => {

        })

    },

    deletePicture(pictureId) {

        return ApiService.delete(`classified/picture/remove/${pictureId}`)

    },

    fetchCategories: async function () {

        try {

            const response = await ApiService.get('categories/fetch')
            return response.data.categories

        } catch (error) {
            return error.message
        }
    },
}

export default ClassifiedManager
