<template>
    <tr>
        <td>
            <b>{{ classified.reference }}</b>
        </td>
        <td>
            {{ classified.createdAt }}
        </td>
        <td>
            {{ classified.clientName }}
        </td>
        <td>
            <b>{{ classified.title }} </b>
        </td>
        <td>
            {{ classified.descriptionShort }}
        </td>
        <td nowrap>
            <div class="btn-group btn-group-sm me-2" role="group" aria-label="First group">
                <router-link v-if="!classified.archived" :to="{name: 'classified_edit', params: {id: classified.id}}"
                             title="Modifier" class="btn btn-primary">
                    <i class="fad fa-pencil-alt"></i>
                </router-link>
                <a v-if="!classified.archived" @click="deleteClassified(classified)" href="#" title="Supprimer"
                   class="btn btn-danger"><i class="fad fa-trash"></i>
                </a>
                <a @click="duplicate(classified.id)" href="#" title="Dupliquer"
                   class="btn btn-secondary">
                    <i class="fad fa-clone"></i>
                </a>
            </div>
            <strong>
                <span title="Annonce archivée" v-if="classified.archived" class="p-2 text-success">
                    <i class="fad fa-file-archive"></i>
                </span>
            </strong>
        </td>
    </tr>
</template>

<script>
export default {
    name: "classified",

    props: ["classified"],

    methods: {
        deleteClassified(classified) {
            if (window.confirm(`Êtes-vous sûr de vouloir supprimer cet annonce (${classified.reference})?`)) {
                this.$store.dispatch('classified/remove', classified)
            }
        },
        duplicate(classifiedId) {
            this.$store.dispatch('classified/duplicate', classifiedId)
        }
    }
}
</script>

<style>
.table th, .table td {
    text-align: left;
}
</style>