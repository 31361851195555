import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'


const UploadManager = {

    fetchSignature: async function (fileRef) {

        return ApiService.query('upload/signature', {fileRef})
    },

    savePicture(picture_infos) {

        return ApiService.post('upload/save-picture', {picture_infos})

    },

}

export default UploadManager
