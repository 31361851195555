<template>
    <section>
        <h5 class="fw-bold mb-3" v-if="getClassifieds.length">Toutes les annonces ({{ getClassifieds.length }})</h5>
        <table v-if="getClassifieds.length" class="table table-bordered">
            <thead>
            <tr>
                <th scope="col">#Reference</th>
                <th scope="col">Date de création</th>
                <th scope="col">Client</th>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <Classified v-for="classified in getClassifieds"
                        :key="classified.reference"
                        :classified="classified">
            </Classified>
            </tbody>
        </table>
        <div v-else class="my-5 py-5 d-flex justify-content-center">
            <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

//import SearchBar from '@/components/Client/Search'
import Classified from '@/components/Classified/Classified'

export default {

    name: "list-classifieds",

    data() {
        return {}
    },

    computed: {
        ...mapGetters('classified', [
            'isFetching',
            'getClassifieds'
        ])
    },

    methods: {
        ...mapActions('classified', [
            'fetch',
            'remove'
        ]),
    },

    created() {
        this.fetch()
    },

    components: {
        //SearchBar,
        Classified
    }
}
</script>

<style>
.card-columns {
    column-count: 4 !important;
}
</style>