<template>
    <div id="login">
        <form class="form-signin">
            <img class="mb-4" :src="require('@/assets/main_logo.png')" alt="" width="250">
            <div v-if="authenticating" class="d-flex justify-content-center text-primary">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else>
                <h1 class="h3 mb-3 fw-bold">Authentification</h1>
                <div>
                    <input v-model="username" type="email" class="form-control" placeholder="Utilisateur" required=""
                           autofocus="" autocomplete="off">
                </div>
                <div>
                    <input v-model="password" type="password" class="form-control" placeholder="Mot de passe" required=""
                           autocomplete="off">
                </div>
                <div class="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me"> Se souvenir de moi
                    </label>
                </div>
                <button @click.prevent="handleSubmit" class="w-100 btn btn-lg btn-primary" type="submit">
                    Se connecter
                </button>
                <p class="my-2 text-muted">© Tahiti Infos - {{ currentYear }}</p>
            </div>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "login",
    data() {
        return {
            username: "",
            password: "",
            currentYear: new Date().getFullYear()
        };
    },
    computed: {
        ...mapGetters('auth', [
            'authenticating',
            'authenticationError',
            'authenticationErrorCode'
        ])
    },
    methods: {
        ...mapActions('auth', [
            'login'
        ]),
        handleSubmit() {
            // Perform a simple validation that email and password have been typed in
            if (this.username !== '' && this.password !== '') {
                this.login({email: this.username, password: this.password})
                this.password = ""
            }
        }
    }
};
</script>

<style>
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>