<template>
    <div></div>
</template>

<script>

import {emitter} from '@/bus';
import {events} from '@/bus';

export default {
    data() {
        return {
            notificationSystem: notificationSystem(),
            toasts: {
                loginSuccess: {
                    title: "Succès",
                    message: "Vous êtes désormais connecté !"
                },
                logoutSuccess: {
                    title: "Succès",
                    message: 'Vous avez bien été déconnecté !'
                },
                client_edit_success: {
                    message: "Le client a bien été modifié"
                },
                client_add_success: {
                    message: "Le client a bien été ajouté"
                },
                client_remove_success: {
                    message: "Le client a bien été supprimé"
                },
                classified_add_success: {
                    message: "La petite annonce a correctement été ajoutée"
                },
                classified_save_success: {
                    message: "La petite annonce a correctement été modifiée"
                },
                invoicing_generate_success: {
                    message: "La facture a correctement été générée"
                },
                auth_failed: {
                    message: "Vous n'avez pas pu être authentifié, vérifiez vos identifiants"
                },
                // --- Upload
                upload_signature_error: {
                    message: "Un problème est survenu lors de la récupération de la signature"
                },
                upload_picture_saved_success: {
                    message: "La photo a bien été enregistrée"
                },
                classified_remove_picture_success: {
                    message: "La photo a bien été supprimée"
                },
                // --- Editing
                edit_generate_success: {
                    message: "Le montage a correctement été généré"
                },
                // --- Publication dates
                publication_date_associated_classifieds: {
                    message: "La date de parution ne peux pas être supprimer car elle est asociée à une ou plusieurs annonces"
                },
                publication_date_selected_success: {
                    message: "La date de parution a correctement été selectionné"
                }
            }
        }
    },
    mounted: function () {
        // --- Called by passing the toast key
        emitter.on(events.TOAST_SHOW, ({name}) => {
                let toast = this.toasts[name]
                this.$toast.success(toast.message);
            }
        );
        // --- Called by passing the toast key
        emitter.on(events.TOAST_ERROR_SHOW, ({key}) => {
                let toast = this.toasts[key]
                this.$toast.error(toast.message);
            }
        );
        // --- ONLY, API BACK
        emitter.on(events.TOAST_API_ERROR_SHOW, ({status, message}) => {
            this.$toast.error(message);
        });
        // --- Called by passing the toast key
        emitter.on(events.TOAST_SUCCESS_SHOW, ({key}) => {
            let toast = this.toasts[key]
            this.$toast.success(toast.message);
        });
    }
}

function notificationSystem() {
    return {
        options: {
            success: {
                position: 'bottomRight',
                theme: 'dark',
                backgroundColor: '#0a890d',
                maxWidth: 300,
                animateInside: true,
            },
            warning: {
                position: 'topLeft'
            },
            error: {
                position: 'bottomRight',
                theme: 'dark',
                backgroundColor: '#a70800',
                maxWidth: 300,
                animateInside: true,
            },
        }
    }
}


</script>