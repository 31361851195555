<template>
  <section>
    <div>
      <p>
        <feather type="search"></feather>
        Résultat de Recherches pour : <b>"{{ query }}"</b>
      </p>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">#Reference</th>
        <th scope="col">Date de création</th>
        <th scope="col">Client</th>
        <th scope="col">Titre</th>
        <th scope="col">Description</th>
        <th colspan="3" scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <Classified v-for="classified in getClassifieds"
                  :key="classified.reference"
                  :classified="classified">
      </Classified>
      </tbody>
    </table>
  </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Classified from '@/components/Classified/Classified'

export default {
  name: "classified-search",
  data() {
    return {}
  },
  computed: {
    ...mapGetters('classified', [
      'getClassifieds'
    ]),
    query() {
      return this.$route.params.query
    }
  },
  components: {
    Classified
  }
}
</script>

<style>
.card-columns {
  column-count: 4 !important;
}
</style>