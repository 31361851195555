import Vuex from 'vuex'

import {auth} from '@/store/modules/auth.module'
import {classified} from '@/store/modules/classified.module'
import {client} from '@/store/modules/client.module'
import {publication} from '@/store/modules/publications.module'
import {upload} from '@/store/modules/upload.module'
import {invoicing} from '@/store/modules/invoicing.module'
import {editing} from '@/store/modules/editing.module'
import {settings} from '@/store/modules/settings.module'

import {emitter} from '@/bus';
import {events} from '@/bus';

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {
        showSuccessToast({commit}, {key}) {
            emitter.emit(events.TOAST_SUCCESS_SHOW, {key: key})
        },
        showErrorToast({commit}, {key}) {
            emitter.emit(events.TOAST_ERROR_SHOW, {key: key});
        }
    },
    modules: {
        auth,
        classified,
        client,
        publication,
        upload,
        invoicing,
        editing,
        settings
    }
})
