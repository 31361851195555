<template>
    <li class="card text-white">
        <img src="" class="card-img preview-img">
        <div class="card-img-overlay">
            <button type="button" @click.prevent="removeFile" class="btn btn-danger btn-sm">Supprimer
            </button>
        </div>
    </li>
</template>

<script>
    export default {

        props: ["file", "previewIndex"],

        data() {
            return {}
        },

        mounted() {

            let reader = new FileReader();

            reader.onload = (e) => {
                this.$el.querySelector('.preview-img').setAttribute('src', e.target.result)
            };

            reader.readAsDataURL(this.file);
        },

        methods: {

            removeFile() {
                this.$emit('remove-file', this.previewIndex);
            },


        }
    }
</script>