import axios from 'axios'
import {TokenService} from '@/services/storage/storage.service'

import {events} from '@/bus';
import {emitter} from '@/bus';

import store from '@/store';

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource) {
        return axios.get(resource)
    },

    query (resource, params) {
        return axios.get(resource, {params})
    },

    search(resource, keywords = '') {
        return axios
            .get(`${resource}?q=${keywords}`)
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)
    },

    mountInterceptors() {
        axios.interceptors.response.use(
            response => response,

            function errorResponseHandler(error) {
                // check for errorHandle config
                if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
                    return Promise.reject(error);
                }

                // if has response show the error
                if (error.response) {
                    console.log(error)
                    emitter.emit(
                        'apiErrorShow',
                        {
                            status: error.response.status,
                            message: error.response.data.message
                        }
                    )

                    if (error.response.status === 403) {
                        store.dispatch('auth/logout')
                    }

                    return error;
                }
            }
        );
    },
}

export default ApiService