<template>
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3 border-bottom">
        <h1 class="fw-bold mb-0">{{ headerTitle }}</h1>
        <div id="view-header__buttons"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            headerTitle: 'Sans Titre'
        }
    },
    computed: {
        routeTitle() {
            return this.$route.meta.title
        },
        routeId() {
            return this.$route.params.id
        }
    },
    created() {
        this.updateTitle()
    },
    watch: {
        routeTitle() {
            this.updateTitle()
        }
    },
    methods: {
        updateTitle() {
            this.headerTitle = this.routeTitle
        },
        stackInvoicing() {
            this.$store.dispatch('invoicing/stackClassified', this.routeId)
        }
    }
}
</script>