<template>
    <Form @save="create"></Form>
</template>

<script>
import Form from '@/components/Settings/Form.vue'
export default {
    components: {Form},
    methods: {
        create(data) {
            this.$store.dispatch('settings/createCategory', data)
        }
    }
}
</script>