<template>
    <section>
        <div class="card text-center">
            <div class="card-header">
            </div>
            <div class="card-body">
                <div v-if="!getPublicationDates.length" class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="card-columns">
                    <div v-if="getPublicationDates.length" v-for="publicationDate in getPublicationDates"
                         :key="publicationDate.title" class="card">
                        <div class="card-body">
                            <h5 class="card-title"><b>{{ publicationDate.title }}</b></h5>
                            <h6 class="card-subtitle mb-2 text-muted">Montage</h6>
                            <p class="card-text">
                                19/09/2019</p>
                            <a @click="createEdit(publicationDate.date)" href="#" class="card-link btn btn-warning">
                                <feather type="plus"></feather>
                                Créer le montage</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        data() {
            return {}
        },
        computed: {
            ...mapGetters('publication', [
                'getPublicationDates'
            ])
        },
        created() {
            this.refreshEvents()
        },
        methods: {
            refreshEvents() {
                this.$store.dispatch('publication/fetch')
            },
            createEdit(date) {
                this.$store.dispatch('editing/createEdit', date)
            }
        }
    }
</script>

<style lang="scss">
    .fc-content {
        padding: 10px;
        &:hover {
            background-color: #a90101;
        }
    }

    .fc-event:hover {
        background-color: #3e0000;
    }

</style>