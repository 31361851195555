<template>
    <tr>
        <td>
            <b>{{ classified.reference }}</b>
        </td>
        <td>
            <feather type="calendar"></feather>
            {{ classified.createdAt }}
        </td>
        <td>
            <feather type="user"></feather>
            {{ classified.clientName }}
        </td>
        <td>
            <b>{{ classified.title }} </b>
        </td>
        <td>
            {{ classified.descriptionShort }}
        </td>
        <td>
            <button v-if="!isStacked" @click="selectClassified" title="Ajouter à la facturation"
                    class="btn btn-sm btn-block btn-success">
                <feather class="no-m" type="plus"></feather>
            </button>
            <button v-else @click="unselectClassified" title="Enlever de la facturation"
                    class="btn btn-sm btn-block btn-danger">
                <feather class="no-m" type="minus"></feather>
            </button>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "classified",

        props: ["classified"],

        computed: {
            isStacked() {
                return this.$store.getters['invoicing/isStacked'](this.classified)
            }
        },

        methods: {
            selectClassified() {
                this.$store.dispatch('invoicing/stackClassified', this.classified)
            },
            unselectClassified() {
                this.$store.dispatch('invoicing/unstackClassified', this.classified)
            }
        }
    }
</script>

<style>
    .table th, .table td {
        text-align: left;
    }
</style>