import SettingsManager from '@/services/api/manager/settings.manager'

const state = {
    categories: [],
    currentCategory: false
}

const actions = {
    getCategories(_) {
        SettingsManager.fetchCategories().then(({data}) => {
            _.state.categories = data
        })
    },
    getCategory(_, id) {
        SettingsManager.fetchCategory(id).then(({data}) => {
            _.state.currentCategory = data
        })
    },
    createCategory(_, category) {
        SettingsManager.createCategory(category).then(({data}) => {
            //_.state.currentCategory = data
        })
    },
}

const getters = {

}

const mutations = {

}

export const settings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}