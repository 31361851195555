<template>
    <section>
        <clientForm :action="1"></clientForm>
    </section>
</template>

<script>
    import clientForm from '@/components/Client/Form'

    export default {
        name: "add-client",
        components: {
            clientForm
        }
    }
</script>