<template>
    <div>
        <div class="large-12 medium-12 small-12 cell">
            <label class="btn btn-light pointer">
                <i class="far fa-plus mr-2"></i> Ajouter Des photos
                <input v-show="false" type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
            </label>
        </div>
        <div v-if="files.length">
            <div class="large-12 medium-12 small-12 cell">
                <div class="card-columns">
                    <Preview v-for="(file, index) in files" :file="file" :key="file.name" :previewIndex="file.name"
                             v-on:remove-file="removeFile">
                    </Preview>
                </div>
            </div>
        </div>
        <p>
            Vous ne pouvez uploder qu'une photo par annonce
        </p>
        <div v-if="files.length" class="large-12 medium-12 small-12 cell">
            <button @click.prevent="upload" class="btn btn-success">
                <i class="fas fa-cloud-upload-alt mr-2"></i> Uploader
            </button>
        </div>
    </div>
</template>

<script>

import Preview from '@/components/Uploader/Preview.vue'
import Vue from 'vue'

export default {

    props: ['fileRef', 'paper-id', 'ref-id'],

    /*
     Defines the data used by the component
     */
    data() {
        return {
            files: []
        }
    },

    components: {
        Preview
    },

    /*
     Defines the method used by the component
     */
    methods: {
        /*
         Adds a file
         */
        addFiles() {
            this.$refs.files.click();
        },

        /*
         Submits files to the server
         */
        submitFiles() {
            /*
             Initialize the form data
             */
            let formData = new FormData();

            /*
             Iteate over any file sent over appending the files
             to the form data.
             */
            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];

                formData.append('files[' + i + ']', file);
            }
        },

        /*
         Handles the uploading of files
         */
        handleFilesUpload() {

            let uploadedFiles = this.$refs.files.files

            /*
             Adds the uploaded file to the files array
             */
            for (let i = 0; i < 1; i++) {

                if (this.checkFileType(uploadedFiles[i])) {
                    this.files.push(uploadedFiles[i]);
                }
            }
        },

        checkFileType(file) {

            let fileType = file.type

            if ((fileType === 'image/jpg') || (fileType === 'image/jpeg') || (fileType === 'image/png')) {
                return true
            }
            return false
        },

        /*
         Removes a select file the user has uploaded
         */
        removeFile(name) {

            let index = this.files.findIndex(file => file.name === name)
            this.files.splice(index, 1);
        },

        /*
         Removes a select file the user has uploaded
         */
        upload() {
            if (!this.fileRef) {
                return false
            }

            this.$store.dispatch(
                'upload/startUpload',
                {
                    files: this.files,
                    fileRef: this.fileRef
                }
            )
        }
    }
}
</script>