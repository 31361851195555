<template>
    <section v-if="getSelectedInvoice">
        <InvoicingPaymentMethods :paymentInfos="getSelectedInvoice.payment"></InvoicingPaymentMethods>
        <button @click="modify" class="btn btn-primary">Modifier</button>
    </section>
</template>

<script>
    import InvoicingPaymentMethods from '@/components/Invoicing/InvoicingPaymentMethods'
    import {mapGetters, mapActions} from "vuex";

    export default {
        computed: {
            id() {
                return this.$route.params.id
            },
            ...mapGetters('invoicing', [
                'getSelectedInvoice'
            ]),
        },
        created() {
            if (this.id) {
                this.$store.dispatch('invoicing/fetchOne', this.id)
            }
        },
        methods: {
            modify() {
                this.$store.dispatch('invoicing/paymentInfosUpdate', this.getSelectedInvoice.payment)
            }
        },
        components: {
            InvoicingPaymentMethods
        }
    }
</script>