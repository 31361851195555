import {createRouter, createWebHistory} from "vue-router";

import {TokenService} from '@/services/storage/storage.service'

import Dashboard from './views/Dashboard.vue'
// --- Views
import Home from './views/Home.vue'
import Login from './views/Login.vue'
// - Classifieds
import AddClassified from './views/Classifieds/Add.vue'
import ListClassifieds from './views/Classifieds/List.vue'
import EditClassified from './views/Classifieds/Edit.vue'
import SearchClassifieds from './views/Classifieds/Search.vue'
// - Clients
import AddClient from './views/Client/Add.vue'
import ListClients from './views/Client/List.vue'
import EditClient from './views/Client/Edit.vue'
// - Publication Dates
import SelectPublicationDates from './views/PublicationDates/Select.vue'
import ListPublicationDates from './views/PublicationDates/List.vue'
// - Invoicing
import CreateInvoice from './views/Invoicing/CreateInvoice'
import InvoicingList from './views/Invoicing/List'
import EditPayment from './views/Invoicing/EditPayment'
// - Editing
import EditingGenerate from './views/Editing/EditingGenerate'
// - Settings
import SettingsIndex from './views/Settings/Index.vue'
import SettingsCategories from './views/Settings/SettingsCategories.vue'
import SettingsCategoryCreate from './views/Settings/SettingsCategoryCreate.vue'
import SettingsCategoryEdit from './views/Settings/SettingsCategoryEdit.vue'
import SettingsList from './views/Settings/SettingsList.vue'


const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                public: true,  // Allow access to even if not logged in
                onlyWhenLoggedOut: true,
                title: 'Authentification'
            }
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Accueil'
            },
            children: [
                {
                    name: 'dashboard',
                    path: '',
                    meta: {
                        title: 'Dashboard'
                    },
                    component: Dashboard
                },
                {
                    name: 'classified_add',
                    path: 'petite-annonce/ajouter',
                    meta: {
                        title: 'Ajouter une petite annonce'
                    },
                    component: AddClassified
                },
                {
                    name: 'classified_edit',
                    path: 'petite-annonce/modifier/:id',
                    meta: {
                        title: 'Modifier une petite annonce'
                    },
                    component: EditClassified
                },
                {
                    name: 'classified_list',
                    path: 'petites-annonces',
                    meta: {
                        title: 'Liste des petites annonces'
                    },
                    component: ListClassifieds
                },
                {
                    name: 'classified_search',
                    path: 'rechercher/annonces/q=:query',
                    meta: {
                        title: 'Recherche de petites annonces'
                    },
                    component: SearchClassifieds
                },
                {
                    name: 'client_add',
                    path: 'client/ajouter',
                    meta: {
                        title: 'Ajouter un client'
                    },
                    component: AddClient
                },
                {
                    name: 'client_list',
                    path: 'clients',
                    meta: {
                        title: 'Liste des clients'
                    },
                    component: ListClients
                },
                {
                    name: 'client_edit',
                    path: 'client/modifier/:id',
                    meta: {
                        title: 'Modifier un client'
                    },
                    component: EditClient
                },
                {
                    name: 'publication_select',
                    path: 'dates-parutions/selectionner',
                    meta: {
                        title: 'Sélectionner les dates de parution'
                    },
                    component: SelectPublicationDates
                },
                {
                    name: 'invoicing_list',
                    path: 'facturation/factures',
                    meta: {
                        title: 'Toutes les factures'
                    },
                    component: InvoicingList
                },
                {
                    name: 'invoicing_classified',
                    path: 'facturation/nouvelle-facture',
                    meta: {
                        title: 'Nouvelle facture'
                    },
                    component: CreateInvoice
                },
                {
                    name: 'invoicing_edit_payment',
                    path: 'facturation/modifier/paiement/:id',
                    meta: {
                        title: 'Modifier les informations de paiement'
                    },
                    component: EditPayment
                },
                {
                    name: 'editing_generate',
                    path: 'montages',
                    meta: {
                        title: 'Montages'
                    },
                    component: EditingGenerate
                },
                {
                    name: 'settings',
                    path: 'parametres',
                    meta: {
                        title: 'Paramètres'
                    },
                    component: SettingsIndex,
                    children: [
                        {
                            name: 'settings_list',
                            path: 'liste',
                            meta: {
                                title: 'Liste des Paramètres'
                            },
                            component: SettingsList
                        },
                        {
                            name: 'settings_categories',
                            path: 'rubriques',
                            meta: {
                                title: 'Gestion des Rubriques'
                            },
                            component: SettingsCategories
                        },
                        {
                            name: 'settings_category_create',
                            path: 'rubriques/creer',
                            meta: {
                                title: 'Création d\'une Rubrique',
                            },
                            component: SettingsCategoryCreate
                        },
                        {
                            name: 'settings_category_edit',
                            path: 'rubriques/:id',
                            meta: {
                                title: 'Modification d\'une Rubrique',
                            },
                            component: SettingsCategoryEdit
                        }
                    ]
                }
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = !!TokenService.getToken();
    document.title = to.meta.title + ' | Application de Gestion PA 2.0'

    if (!isPublic && !loggedIn) {
        return next({
            path: '/login',
            query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
        });
    }
    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        return next('/')
    }
    next();
})


export default router