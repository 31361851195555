import ClientManager from '@/services/api/manager/client.manager'
import {TokenService} from '@/services/storage/storage.service'

const state = {
    fetching: false,
    clients: [],
    client: {
        loaded: {},
        new: {
            firstName: "",
            lastName: "",
            email: '',
            phone: '',
            type: 1
        }
    }
}

const actions = {

    async add({dispatch}, {client}) {

        try {

            const response = await ClientManager.add(client);

            dispatch('showSuccessToast', {
                key: "client_add_success"
            }, {root: true})

            return true

        } catch (error) {
            console.log(error)
            return false
        }
    },

    async edit({dispatch}, {client}) {

        try {

            const response = await ClientManager.edit(client);
            console.log(response)

            dispatch('showSuccessToast', {
                key: "client_edit_success"
            }, {root: true})

            return true

        } catch (error) {
            console.log(error)
            return false
        }
    },

    async fetch({commit}) {

        commit('fetching')

        try {

            let clients = await ClientManager.fetch();

            commit('fetchingEnd')
            commit('setClients', clients)

            return clients

        } catch (e) {
            commit('fetchingError')
            console.log(e)
            return false
        }
    },

    async search({commit}, params) {

        commit('fetching')


        try {

            let clients = await ClientManager.search(params);
            commit('fetchingEnd')
            commit('setClients', clients)

            return clients

        } catch (e) {
            commit('setClients', [])
            commit('fetchingError')
            return false
        }

    },

    async fetchOne({commit}, {id}) {

        commit('fetching')

        try {

            let clients = await ClientManager.fetchOne(id);
            commit('fetchingEnd')
            commit('setClient', clients[0])

            return clients

        } catch (e) {
            commit('fetchingError')
            console.log(e)
            return false
        }
    },

    async remove({commit, dispatch}, {id, email}) {

        try {
            // --- first remove element from view
            commit('removeClient', email)
            // --- then, remove element from server
            let reponse = await ClientManager.remove(id);

            dispatch('showSuccessToast', {
                key: "client_remove_success"
            }, {root: true})

            return clients

        } catch (e) {
            commit('fetchingError')
            console.log(e)
            return false
        }
    },

    clear({commit}) {

        commit('clearClients')
    }
}

const getters = {
    isFetching: (state) => {
        return state.fetching
    },
    getClients: (state) => {
        return state.clients
    },
    loadedClient: (state) => {
        return state.client.loaded
    },
    newClient: (state) => {
        return state.client.new
    },
}

const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchingEnd(state) {
        state.fetching = false;
    },
    fetchingError(state) {
        state.fetching = false;
    },
    setClients(state, clients) {
        state.clients = clients;
    },
    clearClients(state) {
        state.clients = [];
    },
    setClient(state, client) {
        state.client.loaded = client;
    },
    removeClient(state, email) {
        let index = state.clients.findIndex(client => client.email === email)
        state.clients.splice(index, 1)
    },
}

export const client = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}