<template>
    <form>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <h6 class="fw-bold">Titre de l'annonce</h6>
                    <input v-model="classified.title" class="form-control" type="text"
                           placeholder="Titre de l'annonce">
                </div>
                <Description
                    :classified="classified"
                    :description="classified.description"
                    :title="classified.title"
                    :descriptionSupp="classified.descriptionSupp"
                    v-on:description-change="descriptionChanged"
                    v-on:description-supp-change="descriptionSuppChanged"
                >
                </Description>
                <div class="form-group my-3">
                    <h6 class="fw-bold">Choisir un client</h6>
                    <div class="form-check">
                        <input v-model="clientPickerMethod" class="form-check-input" type="radio"
                               name="exampleRadios" id="existingClient"
                               value="1" checked>
                        <label class="form-check-label" for="existingClient">
                            Choisir un client existant
                        </label>
                    </div>
                    <div class="form-check">
                        <input v-model="clientPickerMethod" class="form-check-input" type="radio"
                               name="exampleRadios" id="newClient"
                               value="2">
                        <label class="form-check-label" for="newClient">
                            Créer un nouveau client
                        </label>
                    </div>
                    <div v-if="isClientExisting" class="form-group">
                        <div>
                            <input v-model="searchClient" placeholder="Rechercher un client" class="form-control"
                                   type="text">
                            <ul v-if="isClientListOpen" class="list-group">
                                <li v-for="client in getClients" v-bind:value="client.id"
                                    class="list-group-item custom-control custom-radio">
                                    <div class="custom-control custom-radio">
                                        <input @change="onClientPicked(client)" v-model="classified.clientId"
                                               type="radio"
                                               :id="client.id" :value="client.id"
                                               name="customRadio"
                                               class="custom-control-input me-2">
                                        <label class="custom-control-label" :for="client.id">
                                            <b>{{ client.name }}</b> -

                                            <span v-if="client.type == 1" class="badge bg-light">Particulier</span>
                                            <span v-if="client.type == 2" class="badge bg-dark">Professionnel</span>
                                            <span v-if="client.type == 3"
                                                  class="badge bg-warning">Professionnel crédit</span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="isClientNew" class="form-group">
                        <AddSmall></AddSmall>
                    </div>
                </div>

                <div class="form-group my-3">
                    <div v-if="isGategoriesFetching" class="spinner-border spinner-border-sm mr-2"></div>
                    <h6 class="fw-bold">Rubrique</h6>
                    <select v-model="classified.category" class="form-control"
                            id="exampleFormControlSelect1">
                        <option value="0">Choisir une rubrique</option>
                        <option v-for="category in getCategories" v-bind:value="category.id">
                            {{ category.parent_name }} - {{ category.name }}
                        </option>
                    </select>
                </div>

                <!-- @Deprecated, see if it's possible to delete completely -->
                <div class="form-group my-3 d-none">
                    <h6 class="fw-bold">Date(s) de publication</h6>
                    <div class="mt-2">
                        <span class="btn btn-outline-secondary mr-1"
                              v-for="(date, index) in classified.publicationDates" :key="date">
                            {{ date }} <a @click.prevent="removeDate(date)" href="#" class="badge bg-danger">X</a>
                        </span>
                    </div>
                </div>

                <PublicationDatesPicker @update-dates="updateDates" :advert="classified"></PublicationDatesPicker>

                <div class="form-group my-3">
                    <h6 class="fw-bold">Image associée</h6>
                    <ul class="list-group list-group-horizontal-lg">
                        <li v-for="(picture, index) in classified.pictures" :key="picture.fileName"
                            class="list-group-item">
                            <p>
                                <i class="fal fa-image"></i>
                                {{ picture.fileName }}
                                <a @click.prevent="removePicture(picture.id)" href="#"
                                   class="badge bg-danger ml-2">X</a>
                            </p>
                            <img :src="picture.url" style="width: 200px;" class="rounded float-left" alt=".">
                        </li>
                    </ul>
                    <div>
                        <div>
                            <Uploader :fileRef="classified.fileRef" :paper-id="classified.paperId"
                                      :ref-id="classified.refId"></Uploader>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="classified.pricing" class="col-6">
                <div class="form-group">
                    <label class="text-align-left">
                        <b>Facturation (HT)</b>
                    </label>
                    <div class="custom-control custom-checkbox">
                        <input v-model="classified.custom_pricing" type="checkbox" class="custom-control-input me-2"
                               id="custom pricing">
                        <label class="custom-control-label" for="custom pricing">Facturation personnalisée</label>
                    </div>
                    <div class="my-3" v-if="classified.pricing.success">
                        <small v-if="classified.custom_pricing" class="my-2">
                            Facturation personnalisée
                        </small>
                        <small v-else class="my-3">
                            Facturation naturelle
                        </small>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">XPF</span>
                            <input v-if="!classified.custom_pricing" v-model="classified.pricing.pretax_price"
                                   class="form-control" type="text"
                                   placeholder="Prix généré par la facturation">
                            <input v-if="classified.custom_pricing" v-model="classified.custom_price"
                                   class="form-control" type="text"
                                   placeholder="Tarif de l'annonce personnalisée">
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-danger">
                            <i class="far fa-times-circle"></i>
                            La facturation n'est pas compatible avec l'annonce :
                        </p>
                        <div class="alert alert-danger" role="alert">
                            {{ classified.pricing.error_message }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col">
                <button @click="submit" type="button" class="btn btn-primary">
                    <i class="far fa-plus mr-2"></i>
                    {{ buttonLabel }}
                </button>
            </div>
        </div>
    </form>
</template>


<script>
import Uploader from '@/components/Uploader/Uploader.vue'
import AddSmall from '@/components/Client/AddSmall.vue'
import Description from '@/components/Description/Description.vue'
import PublicationDatesPicker from './PublicationDatesPicker.vue'

import {format} from '@/helpers/date.helper'
import {generateReference} from '@/helpers/key-gen.helper'

import {mapGetters} from "vuex";


export default {
    components: {
        Uploader, AddSmall, Description, PublicationDatesPicker,
    },
    name: "classified-form",
    props: ["classified", 'action'],
    data() {
        return {
            datepickerDate: new Date(),
            picked: new Date(),
            buttonLabel: "",
            clientPickerMethod: 1,
            searchClient: this.classified.clientName,
            advertPubDates: [],
            isClientListOpen: false
        }
    },
    computed: {
        ...mapGetters('publication', [
            'getPublicationDates'
        ]),
        ...mapGetters('classified', [
            'getCategories',
            'isGategoriesFetching',
            'getNextRefId'
        ]),
        ...mapGetters('client', [
            'getClients',
            'isFetching'
        ]),
        isClientExisting() {
            return this.clientPickerMethod == 1
        },
        isClientNew() {
            return this.clientPickerMethod == 2
        },
        test(day) {
            return (day === this.tomorrow)
        }
    },

    created() {
        // --- First, Fetch publication dates, to hydrate date picker.
        this.$store.dispatch('publication/fetch')

        // --- Second, Fetch categories, to hydrate category select input.
        this.$store.dispatch('classified/fetchCategories')

        if (!this.classified.fileRef) {
            this.classified.fileRef = generateReference()
        }

        if (this.action === 'add') {
            this.buttonLabel = "Créer"
        } else {
            this.buttonLabel = "Modifier"
            this.clientPickerMethod = 1
        }
    },

    methods: {
        removePicture(pictureId) {
            this.$store.dispatch(
                'classified/removePicture',
                {pictureId, classified: this.classified}
            )
        },
        updateDates(dates) {
            this.advertPubDates = dates
            console.log(dates)
            this.classified.advertDates = this.advertPubDates.map(d => d.id)
        },
        descriptionChanged(val) {
            this.classified.description = val
        },
        descriptionSuppChanged(val) {
            this.classified.descriptionSupp = val
        },
        submit() {
            this.$emit('submit', this.classified)
        },
        onClientPicked(client) {
            this.searchClient = client.name
        },
        fetchReference() {
            this.$store.dispatch(
                'classified/fetchReference',
                {
                    paper_id: this.classified.paperId
                }
            )
        },
        // --- Add or Remove Invoicable status from this publication date
        toggleInvoicable(advertDateId) {
            this.$store.commit(
                'classified/toogleDateInvoicability', advertDateId
            )
        }
    },
    watch: {
        searchClient(val) {
            if (val.length > 2) {
                this.isClientListOpen = true
                this.$store.dispatch('client/search', {keywords: val})
            } else {
                this.isClientListOpen = false
            }
        },
    },
}
</script>