<template>
    <div class="row">
        <div class="col-6">
            <div v-if="appUser" class="border rounded p-3 mb-4">
                <h5 class="text-secondary"><b><i class="far fa-hand-peace"></i> Bonjour,</b></h5>
                <h1 class="m-0">{{appUser.username}}</h1>
            </div>

            <h5>Bienvenue, sur la version de développement <b>2.0</b> <br>
                de l'application de gestion des Petites Annonces de Tahiti Infos</h5>

            <div class="my-4">
                <h5><b>Je souhaite remonter un bug, dysfontionnement ou amélioration</b></h5>
                <p>La façon la plus simple est de passer par le tableau <a
                    href="https://trello.com/b/7vHOFaeb/tahiti-infos-petites-annonces-debugging"
                    target="_blank"><b>Trello</b> </a><br>
                    Ou alors écrire directement à <b>Jérémie</b>, le développeur web :
                    <a href="mailto:contact@jkj-dev.com"><b>contact@jkj-dev.com</b></a><br>
                    Vous pouvez aussi directement passer le système de chat en temps réel
                    dans le coin en bas à droite de l'écran, afin de décrire votre problème.
                </p>
            </div>
            <div class="my-4 alert alert-danger">
                <h5><b>Facturation</b></h5>
                <p class="m-0">La facturation est désactivé sur la V2 pour le moment</p>
            </div>
        </div>
        <div class="col-6">
            <div class="ms-5 border rounded p-3">
                <h4><b>État des fonctionnalités 2.0</b></h4>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Créer un annonce <span class="badge rounded-pill bg-success float-end">stable</span>
                    </li>
                    <li class="list-group-item">Liste des annonces <span
                        class="badge rounded-pill bg-success float-end">stable</span></li>
                    <li class="list-group-item">Créer un client <span class="badge rounded-pill bg-success float-end">stable</span>
                    </li>
                    <li class="list-group-item">Liste des annonces <span
                        class="badge rounded-pill bg-success float-end">stable</span></li>
                    <li class="list-group-item">Ajouter / Supprimer une date de parution<span
                        class="badge rounded-pill bg-success float-end">stable</span></li>
                    <li class="list-group-item">Générer un montage
                        <span class="badge rounded-pill bg-success float-end">stable</span></li>
                    <li class="list-group-item">Facturation
                        <span class="badge rounded-pill bg-danger float-end">Désactivé</span></li>
                </ul>
            </div>
        </div>
    </div>
    <teleport to="#view-header__buttons" v-if="isMounted">
        <a @click.prevent="logout" href="" class="btn btn-light">
            <i class="fad fa-sign-out-alt me-1"></i> Se déconnecter
        </a>
    </teleport>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            appUser: state => state.auth.user,
        }),
    },
    data: function(){
        return {
            isMounted: false
        }
    },
    mounted(){
        this.isMounted = true
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>
