<template>
    <div class="mb-4">
        <FullCalendar
            :options="calendarOptions"
            :defaultView="'month'"
            :editable="false"
        >
        </FullCalendar>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';

export default {
    components: {FullCalendar},
    data() {
        return {
            calendarOptions: {
                locales: [frLocale],
                locale: 'fr',
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                weekends: false,
                dateClick: this.handleDateClick,
                eventClick: this.removeDate,
                events: this.getPublicationDates,
                //--- Events style
                eventBackgroundColor: '#1e73be',
                eventTextColor: '#fff',
                eventDisplay: 'block',
            },
        }
    },
    created() {
        this.refreshEvents()
    },
    methods: {
        handleDateClick(moment) {
            this.$store.dispatch('publication/select', moment.dateStr).then()
        },
        removeDate({event}) {
            this.$store.dispatch('publication/remove', event._def.publicId).then(() => {
                this.refreshEvents()
            })
        },
        refreshEvents() {
            this.$store.dispatch('publication/fetch').then((dates) => {
                this.calendarOptions.events = dates
            })
        },
    },
}
</script>

<style lang="scss">
.fc a {
    text-decoration: none !important;
}

.fc-event, .fc-day {
    cursor: pointer;
}

.fc-event {
    padding: 5px 3px;
    font-weight: bold;
    border: 3px solid #0088fc;

    &:hover {
        background-color: #de0000 !important;
        border: 3px solid #ba0000;
    }
}

.fc-day-wed:hover, .fc-day:hover, .fc-daygrid-day:hover {
    background-color: #f3f3f3 !important;
}
</style>