import {UserService, AuthenticationError} from '@/services/api/manager/user.manager'
import {TokenService} from '@/services/storage/storage.service'
import router from '@/router'

import {events} from '@/bus'
import {emitter} from "@/bus";

const state = {
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    user: false
}

const actions = {
    async login({commit, dispatch}, {email, password}) {
        commit('loginRequest');
        try {
            const resp = await UserService.login(email, password);
            // --- Authentication failed
            if (!resp.token) {
                dispatch('showErrorToast', {
                    key: "auth_failed"
                }, {root: true})
                commit('endAuthentication')
                return
            }
            // Redirect the user to the page he first tried to visit or to the home view
            router.push({name: 'dashboard'});
            dispatch('identify')
            commit('loginSuccess', resp.token)
            commit('setUsername', resp.username)
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }
            return false
        }
    },
    identify({state}) {
        UserService.identify().then(({data}) => {
            state.user = data
        })
    },
    logout({commit}) {
        UserService.logout()
        commit('logoutSuccess')
        router.push({name: 'login'})
    }
}

const mutations = {
    loginRequest(state) {
        state.authenticating = true;
        state.authenticationError = ''
        state.authenticationErrorCode = 0
    },
    endAuthentication(state) {
        state.authenticating = false;
    },
    loginSuccess(state, accessToken) {
        state.accessToken = accessToken
        state.authenticating = false;
        emitter.emit(events.TOAST_SHOW, {name: 'loginSuccess'})
    },
    loginError(state, {errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },
    logoutSuccess(state) {
        state.accessToken = ''
        emitter.emit(events.TOAST_SHOW, {name: 'logoutSuccess'})
    },

    setUsername(state, username) {
        state.user = {
            username
        }
    }
}
const getters = {
    loggedIn: (state) => {
        return state.accessToken ? true : false
    },

    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },

    authenticationError: (state) => {
        return state.authenticationError
    },

    authenticating: (state) => {
        return state.authenticating
    },

    username: (state) => {
        return state.user.username
    }
}


export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}