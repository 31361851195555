<template>
    <section>
        <clientForm :action="2"></clientForm>
    </section>
</template>

<script>
    import clientForm from '@/components/Client/Form'

    export default {
        name: "edit-client",
        components: {
            clientForm
        }
    }
</script>

<style scoped="true">
    .feather.feather--user-plus {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>