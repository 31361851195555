<template>
    <section>
        <div class="alert alert-warning">
            <h5><b>À noter</b></h5>
            <p class="m-0">
                La génération de montage est associée à la version de l'application sur laquelle elle a été effectué,<br>
                <b>ex: </b> Si la génération d'un montage est effectuée sur la version 2.0, alors le monteur ne pourra y accéder que sur son interface
                en version 2.0 également.<br>
            </p>
        </div>
        <div v-if="!getEdits.length" class="my-4 py-5 d-flex justify-content-center">
            <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <table v-else class="table table-bordered align-middle">
            <thead>
            <tr>
                <th>Date de publication</th>
                <th>Nombre d'annonces associées</th>
                <th class="text-center" style="width:230px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="getEdits.length" v-for="edit in getEdits" :key="edit.edition">
                <td>{{ edit.date }}</td>
                <td>{{ edit.classifiedsCount }}</td>
                <td class="text-center">
                    <button @click="validateEdit(edit.date)" class="btn btn-sm btn-success text-white">
                        <i class="fad fa-cogs me-2"></i>
                        <b>Générer le montage</b>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import searchBar from '@/components/Client/Search'

export default {

    name: "editing-list",

    data() {
        return {}
    },

    created() {

        this.$store.dispatch('editing/fetchEdits')

    },

    computed: {

        getEdits() {
            return this.$store.getters['editing/getEdits']
        }

    },

    methods: {
        validateEdit(edition) {
            this.$store.dispatch('editing/generateEdit', edition)
        }
    }

}
</script>


<style lang="scss">
.card-deck {

    column-count: 4;

}
</style>
