<template>
    <form class="w-100 me-3" data-np-checked="1">
        <input @keyup="change"
               v-model="content"
               type="search"
               class="form-control"
               placeholder="Rechercher une petite annonce"
        >
    </form>
</template>

<script>
export default {
    data() {
        return {
            content: ''
        }
    },
    methods: {
        change() {
            if (this.content.length >= 3) {
                this.$router.push({name: 'classified_search', params: {query: this.content}})
                this.$store.dispatch('classified/fetch', {query: this.content})
            }

            if (this.content.length === 0) {
                this.$store.dispatch('classified/clear')
                this.$router.push({name: 'classified_search', params: {query: this.content}})
            }
        }
    }
}
</script>