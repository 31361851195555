<template>
    <div
        class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <a href="/" class="mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <img style="height: 38px;" :src="require('@/assets/logo-white.svg')" alt="">
        </a>
        <hr class="text-primary">
        <div class="nav nav-pills flex-column mb-auto">
            <li class="nav-item mb-3">
                <router-link class="nav-link p-0" :to="{name: 'dashboard'}">
                    Dashboard
                </router-link>
            </li>
            <h6 class="sidebar-heading d-flex justify-content-start align-items-center my-2">
                <i class="fad fa-newspaper me-2"></i> <span class="fw-bold text-secondary">Petites annonces :</span>
            </h6>
            <div class="ps-1">
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'classified_add'}">
                        <i class="far fa-plus-square me-1"></i> Ajouter
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'classified_list'}">
                        <i class="far fa-list me-1"></i> Liste
                    </router-link>
                </li>
            </div>

            <h6 class="sidebar-heading d-flex justify-content-start align-items-center my-2">
                <i class="fad fa-users me-2"></i> <span class="fw-bold text-secondary">Clients :</span>
            </h6>
            <div class="ps-1">
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'client_add'}">
                        <i class="far fa-plus-square me-1"></i> Ajouter
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'client_list'}">
                        <i class="far fa-list me-1"></i> Liste
                    </router-link>
                </li>
            </div>
            <h6 class="sidebar-heading d-flex justify-content-start align-items-center my-2">
                <i class="fad fa-calendar-alt me-2"></i> <span class="fw-bold text-secondary">Dates de parution :</span>
            </h6>
            <div class="ps-1">
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'publication_select'}">
                        <i class="far fa-check-square me-1"></i> Sélectionner
                    </router-link>
                </li>
            </div>
            <h6 class="sidebar-heading d-flex justify-content-start align-items-center mt-3">
                <i class="fad fa-pager me-2"></i> <span class="fw-bold text-secondary">Montages :</span>
            </h6>
            <div class="ps-1">
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'editing_generate'}">
                        <i class="fal fa-wrench me-1"></i> Générer un montage
                    </router-link>
                </li>
            </div>
            <h6 class="sidebar-heading d-flex justify-content-start align-items-center mt-3">
                <i class="fad fa-pager me-2"></i> <span class="fw-bold text-secondary">Configuration :</span>
            </h6>
            <div class="ps-1">
                <li class="nav-item">
                    <router-link :active-class="'active'" class="nav-link" :to="{name: 'settings_list'}">
                        <i class="fal fa-cog me-1"></i> Paramètres
                    </router-link>
                </li>
            </div>
        </div>
        <h6 class="sidebar-heading d-flex justify-content-start align-items-center mt-3">
            <i class="fad fa-file-invoice-dollar me-2"></i> <span
            class="fw-bold text-secondary">Facturation :</span>
        </h6>
        <div v-if="0" class="ps-3">
            <li class="nav-item">
                <router-link :active-class="'active'" class="nav-link" :to="{name: 'invoicing_classified'}">
                    <i class="far fa-plus-square me-1"></i> Nouvelle facture
                </router-link>
            </li>
            <li class="nav-item">
                <router-link :active-class="'active'" class="nav-link" :to="{name: 'invoicing_list'}">
                    <i class="far fa-list me-1"></i> Toutes les factures
                </router-link>
            </li>
        </div>
        <div v-else><span class="badge bg-info">Désactivé</span></div>
        <div class="my-4">
            <a class="btn btn-primary px-2 py-2" href="https://gestion-pa.jkj-dev.com/" role="alert">
                <i class="far fa-arrow-alt-left ml-2"></i> Accéder à la version <b>1.0</b>
            </a>
        </div>
        <div class="dropdown w-100 bottom-0 py-3 position-absolute">
            <a v-if="0" href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
               id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="https://avatars.dicebear.com/api/pixel-art-neutral/soon.svg" alt=""
                     class="rounded-circle me-2" width="32"
                     height="32">
                <strong>CurrentUser()</strong>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                <li><a class="dropdown-item" href="#">New project...</a></li>
                <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#">Sign out</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        username() {
            return this.$store.getters['auth/username']
        }
    }
}
</script>