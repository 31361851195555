<template>
    <form class="border p-3" action="">
        <div class="col">
            <div class="form-group my-2">
                <label class="text-align-left"><b>Nom du client :</b></label>
                <input v-model="client.lastName" class="form-control form-control-sm" type="text"
                       :class="{'border border-danger': !form.lastName}"
                       placeholder="Nom du client">
            </div>
            <div class="form-group my-2">
                <label class="text-align-left"><b>Prénom du client :</b></label>
                <input v-model="client.firstName" class="form-control form-control-sm" type="text"
                       :class="{'border border-danger': !form.firstName}"
                       placeholder="Prénom du client">
            </div>

            <div class="form-group my-2">
                <label class="text-align-left"><b>Email du client :</b></label>
                <input v-model="client.email" class="form-control form-control-sm" :class="{'border border-danger': !form.email}"
                       type="text"
                       placeholder="Email du client">
            </div>

            <div class="form-group my-2">
                <label class="text-align-left"><b>Numéro de téléphone du client :</b></label>
                <input v-model="client.phone" class="form-control form-control-sm" type="text"
                       placeholder="Numéro de téléphone du client">
            </div>

            <div class="form-group my-3">
                <label class="text-align-left mb-2"><b>Type de client :</b></label>
                <div class="custom-control custom-radio">
                    <input type="radio" id="clientRetail" name="customRadio" class="custom-control-input me-3" value="1"
                           v-model="client.type">
                    <label class="custom-control-label" for="clientRetail">
                        <h6><span class="badge bg-light text-black-50">Particulier</span></h6>
                    </label>
                </div>
                <div class="custom-control custom-radio">
                    <input type="radio" id="clientProfessional" name="customRadio" class="custom-control-input me-3"
                           value="2"
                           v-model="client.type">
                    <label class="custom-control-label" for="clientProfessional">
                        <h6><span class="badge bg-dark">Professionnel</span></h6>
                    </label>
                </div>
                <div class="custom-control custom-radio">
                    <input type="radio" id="clientProfessionalCredit" name="customRadio"
                           class="custom-control-input me-3"
                           value="3"
                           v-model="client.type">
                    <label class="custom-control-label" for="clientProfessionalCredit">
                        <h6><span class="badge bg-warning text-black-50">Professionnel crédit</span></h6>
                    </label>
                </div>
            </div>

            <button @click="submit" type="button" class="btn btn-primary btn-sm fw-bold">
                <i class="far fa-plus-circle me-2"></i>
                Ajouter
            </button>
        </div>
    </form>
</template>

<script>
import clientFormMixin from '@/mixins/client-form.mixin'

export default {
    name: "add-small",
    data() {
        return {action: 1}
    },
    mixins: [clientFormMixin]
}
</script>

<style scoped="true">
.feather.feather--user-plus {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
</style>