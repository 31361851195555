<template>
    <div class="p-3">
        <div class="col-md-8 order-md-1">
            <h4 class="mb-3"><b>Informations de paiement</b> :</h4>
            <p>
                veuillez indiquer le moyen de paiement associé à la facture en cours.
            </p>
            <form class="needs-validation" novalidate>

                <div class="d-block my-3">
                    <div class="custom-control custom-radio">
                        <input v-model="payment.cash" id="cash" type="checkbox" class="custom-control-input"
                               required>
                        <label class="custom-control-label" for="cash"><b>Espèce</b></label>
                        <div class="row mt-2">
                            <div class="col-md-6 mb-3">
                                <label>Montant</label>
                                <input v-model="payment.cashAmount" type="text" class="form-control"
                                       placeholder="Montant">
                            </div>
                        </div>
                    </div>

                    <div class="custom-control custom-radio">
                        <input v-model="payment.check" id="check" name="paymentMethod" type="checkbox"
                               class="custom-control-input" required>
                        <label class="custom-control-label" for="check"><b>Chèque</b></label>
                        <div class="row mt-2">
                            <div class="col-md-6 mb-3">
                                <label>N° de chèque</label>
                                <input v-model="payment.checkNumber" type="text" class="form-control"
                                       placeholder="Numéro de chèque">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>Date</label>

                            </div>
                        </div>
                    </div>

                    <div class="custom-control custom-radio">
                        <input v-model="payment.transfer" id="transfer" name="paymentMethod" type="checkbox"
                               class="custom-control-input" required>
                        <label class="custom-control-label" for="transfer"><b>Virement</b></label>
                        <div class="row mt-2">
                            <div class="col-md-6 mb-3">
                                <label>Date</label>

                            </div>
                        </div>
                    </div>

                    <div class="custom-control custom-radio">
                        <input id="payzen" name="paymentMethod" type="checkbox" class="custom-control-input" required>
                        <label class="custom-control-label" for="payzen"><b>Payzen</b></label>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label>Date</label>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'paymentInfos'
    ],
    data() {
        return {
            payment: null
        }
    },
    created() {
        if (this.paymentInfos)
            this.payment = this.paymentInfos
        else {
            this.payment = this.$store.getters['invoicing/getPaymentInformations']
        }
    },
    components: {}
}
</script>