<template>
    <div class="my-4">
        <input v-model="searchInput" type="text" class="form-control" id="exampleInputEmail1"
               aria-describedby="emailHelp"
               placeholder="Rechercher un client">
        <small id="emailHelp" class="text-left form-text text-muted">Recherche par nom, prénom ou adresse email</small>
        <ul v-if="false" class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link active" href="#">Active</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {
        data() {
            return {
                searchInput: ""
            }
        },

        watch: {
            searchInput: function (val) {

                if (val.length > 3) {
                    this.$store.dispatch('client/search', {
                        keywords: val
                    });
                    return
                }

                if (val.length === 0) {
                    this.$store.dispatch('client/fetch');
                }
            },
        }
    }

</script>