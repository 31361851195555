import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'

const InvoicingManager = {

    generateInvoice(ids, clientId, paymentInfos) {

        return ApiService.post(
            'invoicing/generate',
            {
                classifieds: ids,
                client: clientId,
                payment: paymentInfos
            })
    },

    fetch() {

        return ApiService.get('invoicing/fetch')
    },

    fetchOne(invoiceId) {

        return ApiService.get(`invoicing/fetch-one/${invoiceId}`)
    },

    updatePaymentInformation(invoiceId, paymentInfos) {
        return ApiService.put(`invoicing/payment-infos/update/${invoiceId}`, paymentInfos)
    },
}

export default InvoicingManager
