import InvoicingManager from '@/services/api/manager/invoicing.manager'

import {TokenService} from '@/services/storage/storage.service'
import {generateReference} from '@/helpers/key-gen.helper'

let types = {

    STACK_CLASSIFIED: 'stackClassified',
    UNSTACK_CLASSIFIED: 'unstackClassified',

    SET_GENERATED_INVOICE: 'setGeneratedInvoice',
    SET_INVOICES: 'setInvoices',
    SET_INVOICE: 'setInvoice',
    SET_PAYMENT_INFOS: 'setPaymentInfos',
    CLEAR: 'reset'
}

function getNewPaymentInfos() {
    return {
        cash: false,
        cashAmount: '',

        check: false,
        checkNumber: '',
        checkDate: '',

        transfer: false,
        transferDate: '',

        payzen: false,
        payzenDate: '',
    }
}

const state = {
    stackedClassified: [],
    generatedInvoice: false,
    invoices: [],
    paymentInformations: getNewPaymentInfos(),
    selectedInvoice: null
}

const actions = {

    stackClassified({commit}, classified) {

        commit(types.STACK_CLASSIFIED, classified)
    },
    unstackClassified({commit}, classified) {

        commit(types.UNSTACK_CLASSIFIED, classified)
    },

    fetch({commit}) {

        InvoicingManager.fetch().then(response => {

            commit(types.SET_INVOICES, response.data.invoices)
        })
    },

    fetchOne({commit}, id) {

        InvoicingManager.fetchOne(id).then(response => {

            commit(types.SET_INVOICE, response.data.invoice)
        })
    },

    generateInvoice({dispatch, commit, state}, {clientId}) {

        let ids = []

        state.stackedClassified.forEach((element) => {
            ids.push(element.id)
        });

        let paymentInfos = state.paymentInformations

        InvoicingManager.generateInvoice(ids, clientId, paymentInfos).then(({data}) => {

            if (data.invoice) {
                // Success
                commit(types.SET_GENERATED_INVOICE, data.invoice)

                dispatch('showSuccessToast', {
                    key: "invoicing_generate_success"
                }, {root: true})
            }
        })
    },

    paymentInfosUpdate({dispatch, commit, state}, PaymentInfos) {
        InvoicingManager.updatePaymentInformation(
            state.selectedInvoice.id,
            PaymentInfos
        ).then(({data}) => {

            if (data.invoice) {
                // Success
                commit(types.SET_GENERATED_INVOICE, data.invoice)

                dispatch('showSuccessToast', {
                    key: "invoicing_generate_success"
                }, {root: true})
            }
        })
    },

    clear({commit}) {

        commit(types.CLEAR)
    }
}

const getters = {

    getClassifieds(state) {
        return state.stackedClassified
    },

    isStacked(state) {
        return classified => state.stackedClassified.find(item => {
            return item.id === classified.id
        });
    },

    getGeneratedInvoice(state) {
        return state.generatedInvoice
    },

    getInvoices(state) {
        return state.invoices
    },

    getSelectedInvoice(state) {
        return state.selectedInvoice
    },

    getPaymentInformations(state) {
        return state.paymentInformations
    }
}

const mutations = {

    [types.STACK_CLASSIFIED] (state, classified) {
        state.stackedClassified.push(classified)
    },

    [types.UNSTACK_CLASSIFIED] (state, classified) {
        let index = state.stackedClassified.findIndex(c => c.id === classified.id)
        state.stackedClassified.splice(index, 1)
    },

    [types.SET_GENERATED_INVOICE] (state, invoice) {
        state.generatedInvoice = invoice
        state.stackedClassified = []
    },

    [types.SET_INVOICES] (state, invoices) {
        state.invoices = invoices
    },

    [types.SET_INVOICE] (state, invoice) {
        state.selectedInvoice = invoice
    },

    [types.SET_PAYMENT_INFOS] (state, paymentInfos) {
        state.paymentInformations = paymentInfos
    },

    [types.CLEAR] (state) {
        state.generatedInvoice = false
        state.stackedClassified = []
        state.paymentInformations = getNewPaymentInfos()
    },
}

export const invoicing = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}