const files = require.context('./events', false, /\.js$/)
const types = []

// Loop throught Files
files.keys().forEach(key => {

    // If current file is ./index.js, we skip
    if (key === './index.js') return

    // Get all types in current file
    let fileTypes = files(key).default

    // loop trought types and add all types
    for (let fileType in fileTypes) {
        types[fileType] = fileTypes[fileType]
    }

})

export let events = types