<template>
    <section class="my-4">

        <div v-if="generatedInvoice">
            <div class="alert alert-success" role="alert">
                La Facture <b>TI{{ generatedInvoice.number }}</b> a correctement été générée
            </div>
            <a class="btn btn-success" target="_blank" :href="generatedInvoice.presigned_url">
                <feather type="eye"></feather>
                Voir la facture
            </a>
            <button @click="resetInvoiceGeneration()" class="btn btn-secondary ml-2">
                <feather type="rotate-cw"></feather>
                Générer une autre facture
            </button>
        </div>

        <div v-else>

            <div class="form-group my-4">
                <div v-if="0" class="alert alert-danger">La facturation n'est pas encore disponible</div>
                <div v-if="!clientPicked">
                    <input v-model="searchClient" placeholder="Rechercher un client" class="form-control"
                           type="text">
                    <ul class="list-group">
                        <li v-for="client in clients" v-bind:value="client.id"
                            class="list-group-item custom-control custom-radio">
                            <div class="custom-control custom-radio">
                                <input @change="onClientPicked(client)" type="radio"
                                       :id="client.id" :value="client.id"
                                       name="customRadio"
                                       class="custom-control-input">
                                <label class="custom-control-label" :for="client.id">
                                    <b>{{ client.name }}</b> -
                                    <p v-if="client.type == 1" class="badge badge-light">Particulier</p>
                                    <p v-if="client.type == 2" class="badge badge-dark">Professionnel</p>
                                    <p class="badge badge-warning"
                                       v-if="client.type == 3">Professionnel crédit</p>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <ul class="list-group">
                        <li class="list-group-item active">
                            <div>
                                <button @click="resetClient" class="badge btn badge-danger mr-2 ">X</button>
                                <b>{{ clientPicked.name }}</b>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="my-4" v-if="clientPicked">
                    <h6>
                        <feather type="plus"></feather>
                        <b>Sélectionnez les petites annonces à facturer</b></h6>
                    <InvoicingClassifiedTable v-if="classifieds.length"
                                              :classifieds="classifieds">
                    </InvoicingClassifiedTable>
                    <div v-else>
                        <div class="alert alert-secondary">
                            <feather type="info"></feather>
                            Aucune annonce à facturer pour ce client, peut-être ont-elles déjà été facturées.
                        </div>
                    </div>
                </div>

                <div class="my-4" v-if="clientPicked">
                    <InvoicingPaymentMethods v-on:update="paymentMethodsUpdated"></InvoicingPaymentMethods>
                </div>

                <a @click.prevent="generateInvoice" v-if="classifieds.length" href="" class="btn btn-primary">
                    <feather type="calendar"></feather>
                    Générer la facture
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import InvoicingClassifiedTable from "@/components/Invoicing/InvoicingClassifiedTable"
    import InvoicingPaymentMethods from "@/components/Invoicing/InvoicingPaymentMethods"

    export default {
        name: "invoicing",
        data() {
            return {
                searchClient: '',
                clientPicked: false
            }
        },
        computed: {
            classifieds() {
                return this.$store.getters['classified/getClassifieds']
            },
            clients() {
                return this.$store.getters['client/getClients']
            },
            generatedInvoice() {
                return this.$store.getters['invoicing/getGeneratedInvoice']
            }
        },
        created() {
            this.$store.dispatch('classified/clear')
            this.$store.dispatch('client/clear')
        },
        methods: {
            generateInvoice() {
                this.$store.dispatch('invoicing/generateInvoice', {clientId: this.clientPicked.id})
            },
            onClientPicked(client) {
                this.clientPicked = client
                this.$store.dispatch('classified/fetch', {client: client.id, archived: false})
            },
            resetClient() {
                this.clientPicked = false
            },
            resetInvoiceGeneration() {
                this.$store.dispatch('classified/clear')
                this.$store.dispatch('client/clear')
                this.$store.dispatch('invoicing/clear')
                this.searchClient = ''
                this.clientPicked = false
            },
            paymentMethodsUpdated(paymentInfos) {
                console.log(paymentInfos)
            }
        },
        watch: {
            searchClient(val) {
                if (val.length > 2) {
                    this.$store.dispatch('client/search', {keywords: val, credit: false})
                }
            },
        },
        components: {
            InvoicingClassifiedTable,
            InvoicingPaymentMethods
        }
    }
</script>

<style>
</style>