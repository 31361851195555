<template>
  <section>
    <searchBar></searchBar>
    <div v-if="isFetching" class="my-5 py-5 d-flex justify-content-center">
      <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div v-if="!getClients.length">
        <div class="alert alert-primary" role="alert">
          Aucun client n'a été trouvé :
          <router-link :to="{name: 'client_add'}" class="btn btn-primary btn-sm">
            + Créer un client
          </router-link>
        </div>
      </div>
      <table v-else class="table table-bordered">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Tél</th>
          <th>Type</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="getClients.length" v-for="client in getClients" :key="client.email">
          <td><b>{{ client.name }}</b></td>
          <td><span class="card-subtitle text-muted">{{ client.email }}</span></td>
          <td>{{ client.phone }}</td>
          <td>
            <span v-if="client.type === 1" class="badge bg-light text-black-50">Particulier</span>
            <span v-if="client.type === 2" class="badge bg-dark">Professionnel</span>
            <span v-if="client.type === 3"
                  class="badge bg-warning text-black-50">Professionnel crédit</span>
          </td>
          <td>
            <div class="btn-group btn-group-sm me-2 float-end" role="group" aria-label="First group">
              <router-link class="btn btn-primary" :title="'Modifier'"
                           :to="{name: 'client_edit', params: {id: client.id}}">
                <i class="fad fa-pencil-alt"></i>
              </router-link>
              <a href="#" @click="deleteClient(client)" title="Supprimer"
                 class="btn btn-danger">
                <i class="fad fa-trash"></i>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import searchBar from '@/components/Client/Search'

export default {
  name: "list-clients",
  data() {
    return {}
  },
  computed: {
    ...mapGetters('client', [
      'isFetching',
      'getClients'
    ])
  },
  methods: {
    ...mapActions('client', [
      'fetch',
      'remove'
    ]),
    deleteClient(client) {
      if (window.confirm(`Êtes-vous sûr de vouloir archiver ce client (${client.name})?`)) {
        this.remove(client)
      }
    }
  },
  created() {
    this.fetch()
  },
  components: {
    searchBar
  }
}
</script>