import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'


const ClientManager = {

    add: async function (client) {

        return await ApiService.post('client/add', client)
    },

    edit: async function (client) {

        return await ApiService.put('client/edit', client)
    },

    remove: async function (id) {

        return await ApiService.delete(`client/remove/${id}`)
    },

    fetch: async function () {

        try {

            const response = await ApiService.get('client/fetch')
            return response.data.clients
        } catch (error) {
            return error.message
        }
    },

    search: async function (params) {

        try {

            const response = await ApiService.query('client/fetch', params)
            return response.data.clients
        } catch (error) {
            return error.message
        }
    },

    fetchOne: async function (id) {

        try {

            const response = await ApiService.get(`client/fetch/${id}`)
            return response.data.clients
        } catch (error) {
            return error.message
        }
    },
}

export default ClientManager
