import ApiService from '@/services/api/api.service'
import {TokenService} from '@/services/storage/storage.service'

const EditingManager = {

    fetch(params) {

        return ApiService.query('editing/fetch', {params})
    },

    generate(edition) {

        return ApiService.put('editing/generate', {edition})
    },
}

export default EditingManager
